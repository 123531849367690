import React from 'react'
import { Container, Table } from 'react-bootstrap'
import Layout from "../components/Layout"
import '../styles/management.css'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';



function Management() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);

  return (
    <div className='overflow-hidden '>
      <Head title="Management"/>
        <Layout>
            <Container className="px-5">
                <div className='management'>
                    <header className="sectionHeader halfBorder my-5" as="h1">Management</header>

                    <section className='membersParts row'>
                        <div className='col-lg-6 col-12 leftPart' data-aos="flip-left">
                            <Table  className='table  text-center   table-striped table-bordered '>
                                
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Members of Trust</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className=' align-middle'>1</td>
                                        <td>
                                            <span>Founder</span> <br />
                                            Shri Shri Vishwesha Teertha Swamiji Of Pejawar Mutt,Udupi
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>2</td>
                                        <td>
                                            <span>President</span> <br />
                                            Shri Shri Vishwaprasanna Teertha Swamiji Of Pejawar Mutt,Udupi
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>3</td>
                                        <td>
                                            <span>Working President</span> <br />
                                            Shri. Shrikanth G.Kemtur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>4</td>
                                        <td colSpan="2">
                                            <span>Treasurer</span> <br />
                                            Dr.V.G.Nadagouda
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>5</td>
                                        <td>
                                            <span>Secretary</span> <br />
                                            Dr. Sathyamurthi Achar G.K
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>6</td>
                                        <td>
                                            <span>Joint Secretary</span> <br />
                                            Shri. Gopal Kulkarni
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>7</td>
                                        <td>
                                            <span>Joint Secretary</span> <br />
                                            Shri. G.S.Mysore
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className='rightPart col-lg-6 col-12 d-flex justify-content-center align-items-center' data-aos="zoom-in">
                            <h1>Members of Trust</h1>
                        </div>
                    </section>

                    <div className="managementColorBar"></div>

                    <section className='counsilParts row'>
                        <div className='leftPart col-lg-6 col-12 d-flex justify-content-center align-items-center' data-aos="zoom-in">
                            <h1>Counsil of Advisor</h1>
                        </div>

                        <div className='col-lg-6 col-12 rightPart' data-aos="flip-right">
                            <Table  className='table  text-center   table-striped table-bordered '>
                                
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Counsil of Advisor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className=' align-middle'>1</td>
                                        <td>
                                            <span>Prof .N. Vajrakumar,</span> <br />
                                            Secretary.Janata Shikshana Samiti, Vidyagiri, Dharwad
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>2</td>
                                        <td>
                                            <span>Prof. C. C .Dixit, M.A</span> <br />
                                            Rtd. Principal Lingaraj College, Belgaum
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=' align-middle'>3</td>
                                        <td colSpan="2">
                                            <span>Prof.Patre Prakash, MA , M.Phill</span> <br />
                                            Principal of Govt College, Hamsabhavi
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </section>

                </div>
                
            </Container>
        </Layout>
    </div>
  )
}

export default Management
